import { L_ACTIVE_CHECK_LF_BOX, SELECT_INPUT, TEXT_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const MODULE_NAME = 'set-fulfillment-dispatch'
  const MODULE_NAME_CLONE = 'cloneData'
  const autoSuggestTableColumns = [
    {
      key: 'name',
      label: 'Name',
    },
    {
      key: 'is_active',
      label: 'Status',
      getValue(data) {
        return data ? 'Active' : 'Inactive'
      },
    },
  ]

  const tableColumns = [
    {
      key: 'actions',
      label: '',
      thStyle: { width: '10%' },
    },
    {
      key: 'name',
      label: 'Name',
      thStyle: { paddingLeft: '0px', width: '30%' },
    },
    {
      key: 'id',
      label: 'ID',
      thStyle: { paddingLeft: '10px' },
    },
    {
      key: 'is_active',
      label: 'Status',
      thStyle: { paddingLeft: '10px' },
    },
    {
      key: 'is_default',
      label: 'Default',
      thStyle: { width: '20%' },
    },
  ]

  const dispatchFields = {
    name: {
      type: TEXT_INPUT,
      label: 'Name',
      rules: 'required',
      placeholder: 'Type...',
    },
    is_active: {
      type: L_ACTIVE_CHECK_LF_BOX,
      label: 'Status',
    },
    ecommerce_shipping_method_id: {
      type: SELECT_INPUT,
      label: 'Ecommerce shipping method',
      store: 'ecommerceShippingMethod',
    },
    linked_supplier_account_id: {
      type: SELECT_INPUT,
      store: 'customersList',
      options: {
        label: 'cust_name_dba',
      },
      fParams: {
        f: {
          allow_to_purchase_from_supplier: 1,
        },
      },
      fSearchParams: {
        f: {
          allow_to_purchase_from_supplier: 1,
        },
      },
      label: 'CS-Cart shipping method',
    },
  }

  const chargeAtOrderApproval = 1

  return {
    MODULE_NAME,
    MODULE_NAME_CLONE,
    tableColumns,
    dispatchFields,
    autoSuggestTableColumns,
    chargeAtOrderApproval,
  }
}
