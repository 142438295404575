<template>
  <div>
    <l-draggable-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumns"
      :create-page-url="{ name: 'settings-fulfillment-dispatch-create' }"
      trash-list-path="settings-fulfillment-dispatch-trash-list"
      :auto-suggest-style="{width:'380px'}"
      @onChoose="onChoose"
    >
      <template
        #cell(actions)="{ data }"
      >
        <div
          class="d-flex justify-content-start"
        >
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            icon="LTrashIconKits"
            :class="data.default ? 'd-none' : 'mr-1'"
            size="32"
            class="cursor-pointer"
            :title="$t('Set Inactive')"
            @click="remove(data)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Update')"
            icon="LPenIconUpdated"
            size="32"
            class="mr-1 cursor-pointer"
            @click="gotoNewPage({
              name: 'settings-fulfillment-dispatch-update',
              params: { id: data.id },
            }, $event)"
          />
          <feather-icon
            v-b-tooltip.noninteractive.hover.bottom
            icon="LCopyIconKits"
            size="32"
            class="cursor-pointer"
            :title="$t('Copy')"
            @click="duplicate(data)"
          />
        </div>
      </template>
      <template #cell(name)="{data}">
        <span style="margin-left: -10px">
          {{ data.name }}
        </span>
      </template>
      <template #cell(id)="{data}">
        <span style="display: flex; width: 10px; flex-direction: column; align-items: center">
          {{ data.id }}
        </span>
      </template>
      <template #cell(is_active)="{data}">
        {{ data['is_active'] ? 'Active' : 'Inactive' }}
      </template>
      <template #cell(is_default)="{data}">
        <div
          :id="data.id"
          class="d-flex align-items-center justify-content-center w-50"
          style="width: max-content; height: 20px;  "
          :style="{pointerEvents: isLoadingOfSetDisabled ? 'none':'all'}"
          @click="setDefault(data)"
        >
          <div class="cursor-pointer">
            <b-form-radio
              :id="data.id.toString()"
              :checked="data.is_default"
              style="pointer-events: none"
              :value="true"
              :disabled="isLoadingOfSetDisabled"
            />
            <b-spinner
              v-if="isSettling(data.id)"
              style="margin-bottom: 4px;"
              class="ml-1"
              small
              label="Small Spinner"
              type="grow"
              variant="success"
            />
          </div>
        </div>
      </template>
    </l-draggable-table>
  </div>
</template>
<script>
import {
  BFormRadio, BSpinner,
  VBTooltip,
} from 'bootstrap-vue'
import LDraggableTable from '@/views/components/LDraggableTable.vue'
import config from '../config'

export default {
  name: 'DispatchList',
  components: {
    BSpinner,
    BFormRadio,
    LDraggableTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      isLoadingOfSetDisabled: false,
      idOfSettledDefault: null,
    }
  },
  methods: {
    refetchData() {
      this.$refs.lTableRef.fetchTableItemsList()
    },
    isSettling(id) {
      return this.isLoadingOfSetDisabled && this.idOfSettledDefault === id
    },
    setDefault(data) {
      const id = Number(data.id)
      this.isLoadingOfSetDisabled = true
      this.idOfSettledDefault = id
      this.sendNotification(
        this,
        id,
        `${this.MODULE_NAME}/setDefault`,
      )
        .then(() => {
          this.refetchData()
          this.isLoadingOfSetDisabled = false
        })
    },
    remove(data) {
      const { id } = data
      this.confirmNotification(this, [id], `${this.MODULE_NAME}/moveToTrash`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
        .then(() => {
          this.refetchData()
        })
    },
    duplicate(data) {
      const { id } = data
      this.$refs.lTableRef.isBusy = true
      this.$store.dispatch(`${this.MODULE_NAME}/duplicate`, id).then(() => {
        this.refetchData()
      }).catch(() => {
        this.$refs.lTableRef.isBusy = false
      })
    },
    onChoose(item) {
      const { moved: { element: { id }, newIndex } } = item
      this.sendNotification(
        this, { dispatch: Number(id), position: Number(newIndex) },
        `${this.MODULE_NAME}/setPosition`,
      ).then(() => {
        this.refetchData()
      })
    },
  },
  setup() {
    const { MODULE_NAME, tableColumns } = config()

    return {
      MODULE_NAME,
      tableColumns,
    }
  },
}
</script>
