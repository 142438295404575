var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('l-draggable-table',{ref:"lTableRef",attrs:{"module-name":_vm.MODULE_NAME,"table-columns":_vm.tableColumns,"create-page-url":{ name: 'settings-fulfillment-dispatch-create' },"trash-list-path":"settings-fulfillment-dispatch-trash-list","auto-suggest-style":{width:'380px'}},on:{"onChoose":_vm.onChoose},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex justify-content-start"},[_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"cursor-pointer",class:data.default ? 'd-none' : 'mr-1',attrs:{"icon":"LTrashIconKits","size":"32","title":_vm.$t('Set Inactive')},on:{"click":function($event){return _vm.remove(data)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"mr-1 cursor-pointer",attrs:{"title":_vm.$t('Update'),"icon":"LPenIconUpdated","size":"32"},on:{"click":function($event){return _vm.gotoNewPage({
            name: 'settings-fulfillment-dispatch-update',
            params: { id: data.id },
          }, $event)}}}),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.noninteractive.hover.bottom",modifiers:{"noninteractive":true,"hover":true,"bottom":true}}],staticClass:"cursor-pointer",attrs:{"icon":"LCopyIconKits","size":"32","title":_vm.$t('Copy')},on:{"click":function($event){return _vm.duplicate(data)}}})],1)]}},{key:"cell(name)",fn:function(ref){
          var data = ref.data;
return [_c('span',{staticStyle:{"margin-left":"-10px"}},[_vm._v(" "+_vm._s(data.name)+" ")])]}},{key:"cell(id)",fn:function(ref){
          var data = ref.data;
return [_c('span',{staticStyle:{"display":"flex","width":"10px","flex-direction":"column","align-items":"center"}},[_vm._v(" "+_vm._s(data.id)+" ")])]}},{key:"cell(is_active)",fn:function(ref){
          var data = ref.data;
return [_vm._v(" "+_vm._s(data['is_active'] ? 'Active' : 'Inactive')+" ")]}},{key:"cell(is_default)",fn:function(ref){
          var data = ref.data;
return [_c('div',{staticClass:"d-flex align-items-center justify-content-center w-50",staticStyle:{"width":"max-content","height":"20px"},style:({pointerEvents: _vm.isLoadingOfSetDisabled ? 'none':'all'}),attrs:{"id":data.id},on:{"click":function($event){return _vm.setDefault(data)}}},[_c('div',{staticClass:"cursor-pointer"},[_c('b-form-radio',{staticStyle:{"pointer-events":"none"},attrs:{"id":data.id.toString(),"checked":data.is_default,"value":true,"disabled":_vm.isLoadingOfSetDisabled}}),(_vm.isSettling(data.id))?_c('b-spinner',{staticClass:"ml-1",staticStyle:{"margin-bottom":"4px"},attrs:{"small":"","label":"Small Spinner","type":"grow","variant":"success"}}):_vm._e()],1)])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }