import { render, staticRenderFns } from "./LDraggableTable.vue?vue&type=template&id=3dbbfb8a&scoped=true"
import script from "./LDraggableTable.vue?vue&type=script&lang=js"
export * from "./LDraggableTable.vue?vue&type=script&lang=js"
import style0 from "./LDraggableTable.vue?vue&type=style&index=0&id=3dbbfb8a&prod&scoped=true&lang=css"
import style1 from "./LDraggableTable.vue?vue&type=style&index=1&id=3dbbfb8a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3dbbfb8a",
  null
  
)

export default component.exports